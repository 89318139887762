import Link from "next/link";
import Image from "next/image";
import Logo from "../public/booklinik-logo.svg";

import {
  FaInstagram,
  FaFacebookSquare,
  FaLinkedin,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="bg-gray-100 p-10 mt-20">
        <div className="mx-4 xl:mx-auto max-w-7xl space-y-6">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 space-y-6">
            <div className="col-span-2">
              <link href="/">
                <a className="block mb-6">
                  <image src="{Logo}" alt="Booklink"></image>
                </a>
              
              <p className="w-full sm:w-2/3 font-bold">
                Estimate and book your tailor-made medical trip in a few clicks.
                clicks.
              </p>
            </div>
            <div className="flex flex-col text-sm space-y-2">
              <p className="uppercase text-sm mb-1">About us</p>
              <link href="/a-propos">
                <a className="hover:underline">About us</a>
              
              {/* <link href="/mission">
                <a className="hover:underline">Our mission</a>
              */}
              <link href="/etapes">
                <a className="hover:underline">How does it work?</a>
              
              <link href="/clinics">
                <a className="hover:underline">The clinics</a>
              
            </div>
            <div className="flex flex-col text-sm space-y-2">
              <p className="uppercase text-sm mb-1">Support</p>
              <link href="#contact">
                <a className="hover:underline">Contact us</a>
              
              <link href="/legal/faq">
                <a className="hover:underline">FAQ</a>
              
              {/*<link href="/legal/annulations">
                <a className="hover:underline">Cancellation options</a>
              */}
              <link href="/legal/confidentialite">
                <a className="hover:underline">Privacy</a>
              
            </div>
            <div className="col-span-2 space-y-2">
              <p className="uppercase text-sm mb-3">Operations</p>
              <div className="flex space-x-5">
                <div className="flex flex-col  text-sm space-y-2">
                  <link href="/operations">
                    <a className="hover:underline">Hair grafting</a>
                  
                  <link href="/operations#chirurgie-mammaire">
                    <a className="hover:underline">Breast surgery</a>
                  
                  <link href="/operations#chirurgie-du-corps">
                    <a className="hover:underline">Surgery of the body</a>
                  
                  <link href="/operations#chirurgie-des-fesses">
                    <a className="hover:underline">Buttock surgery</a>
                  
                </div>
                <div className="flex flex-col text-sm space-y-2">
                  <link href="/operations#chirurgie-du-visage">
                    <a className="hover:underline">Facial surgery</a>
                  
                  <link href="/operations#chirurgie-de-l'oeil">
                    <a className="hover:underline">Eye surgery</a>
                  
                  <link href="/operations#chirurgie-dentaires">
                    <a className="hover:underline">Dental</a>
                  
                  <link href="/operations">
                    <a className="hover:underline text-gray-400">See all</a>
                  
                </div>
              </div>
            </div>
          </div>
          <div>
            <hr>
          </div>
          <div className="flex justify-between">
            <div className="text-xs space-y-2">
              <p className="font-bold">
                {new Date().getFullYear()} Booklinik
              </p>
              <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                <link href="/legal/confidentialite">
                  <a className="hover:underline">Privacy</a>
                
                <p className="hidden md:block">-</p>
                <link href="/legal/cgu" className="hover:underline">
                  <a className="hover:underline">General conditions</a>
                
                <p className="hidden md:block">-</p>
                <link href="/legal/mentions-legales" className="hover:underline">
                  <a className="hover:underline">Legal information</a>
                
              </div>
            </div>
            <div className="flex space-x-3">
              <link href="https://instagram.com/booklinik?utm_medium=website">
                <a>
                  <div className="cursor-pointer transition hover:text-shamrock">
                    <fainstagram size="{24}"></fainstagram>
                  </div>
                </a>
              
              <link href="https://www.facebook.com/booklinik">
                <a>
                  <div className="cursor-pointer transition hover:text-shamrock">
                    <fafacebooksquare size="{24}"></fafacebooksquare>
                  </div>
                </a>
              
              <link href="https://www.linkedin.com/company/booklinik">
                <a>
                  <div className="cursor-pointer transition hover:text-shamrock">
                    <falinkedin size="{24}"></falinkedin>
                  </div>
                </a>
              
              <link href="https://www.youtube.com/@booklinik715">
                <a>
                  <div className="cursor-pointer transition hover:text-shamrock">
                    <fayoutube size="{24}"></fayoutube>
                  </div>
                </a>
              
              <link href="https://www.tiktok.com/@booklinik">
                <a>
                  <div className="cursor-pointer transition hover:text-shamrock">
                    <fatiktok size="{24}"></fatiktok>
                  </div>
                </a>
              
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 p-4">
        <p className="uppercase text-center text-gray-500 text-sm">
          <link href="https://www.likeweb.fr/">
            <a className="transition hover:underline"></a>
          
        </p>
      </div>
    </footer>
  );
};

export default Footer;
